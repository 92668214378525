<template>
  <div>
    <Carousel />

    <v-container fluid >
       
          <v-row class="mt-2 mb-10">
            <Card titulo="RTM Mes" color="#ff9f99" valor="0" />
            <Card titulo="Carro" color="#f00f99" valor="0" />
            <Card titulo="Motos" color="blue" valor="0" />
            <Card titulo="Preventivas" color="cyan" valor="0" />
          </v-row>

    </v-container>
  </div>
</template>

<script>
import Carousel from "@/components/shared/Carousel";
import Card from "@/components/Card";

export default {
  data: () => ({
    titulo: "baños",
  }),
  components: {
    Carousel,
    Card,
  },
};
</script>
