import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_NO_API,
});

export default {
  getToken() {
    return apiClient.get("/sanctum/csrf-cookie");
  },
};
