<template>
   <v-parallax
   height="200"
    dark
    src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <h1 class="text-h4 font-weight-thin mb-4">
          Certiexpress Cartago
        </h1>
        <h4 class="subheading">
          
        </h4>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        src:"https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
      },
   
    ],
  }),
};
</script>
<style></style>
